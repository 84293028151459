<template>
  <div class="content block-el">
    <PageHeader :title="$tc('app.provider', 2)" />
    <div class="page-content container-fluid">
      <FormComponent ref="store-provider" @submit="create">
        <div class="row">
          <div class="col-12">
            <div v-if="!form.type" class="card-deck m-b-100">
              <div @click="setProvider('sinch', 'HTTP')" class="card card-elevate text-center">
                <img class="img-fluid align-middle" src="assets/img/providers/sinch.png"
                  :style="'padding: 1.25rem; objectPosition: 0 -30px;'" />
                <div class="card-img-overlay d-flex flex-column justify-content-end">
                  <h5 class="card-title m-t-20">Sinch</h5>
                  <small class="text-muted">HTTP</small>
                </div>
              </div>
              <div @click="setProvider('tww', 'SMPP')" class="card card-elevate text-center">
                <img class="img-fluid align-middle" src="assets/img/providers/tww.png"
                  :style="'padding: 1.25rem; objectPosition: 0 -30px;'" />
                <div class="card-img-overlay d-flex flex-column justify-content-end">
                  <h5 class="card-title m-t-20">TWW</h5>
                  <small class="text-muted">SMPP</small>
                </div>
              </div>
              <div @click="setProvider('zenvia', 'HTTP')" class="card card-elevate text-center">
                <img class="img-fluid align-middle provider-img-size" src="assets/img/providers/zenvia.jpg"
                  :style="'objectPosition: 0 -2px; marginLeft: auto; marginRight: auto;'" />
                <div class="card-img-overlay d-flex flex-column justify-content-end">
                  <h5 class="card-title m-t-20">Zenvia</h5>
                  <small class="text-muted">HTTP</small>
                </div>
              </div>
              <div @click="setProvider('mtexpert', 'HTTP')" class="card card-elevate text-center">
                <img class="img-fluid align-middle" src="assets/img/providers/mtexpert.png"
                  :style="'padding: 1.25rem; objectPosition: 0 -30px;'" />
                <div class="card-img-overlay d-flex flex-column justify-content-end">
                  <h5 class="card-title m-t-20">MTExpert</h5>
                  <small class="text-muted">HTTP</small>
                </div>
              </div>
              <div @click="setProvider('sendgrid', 'HTTP')" class="card card-elevate text-center">
                <img class="img-fluid align-middle" src="assets/img/providers/sendgrid.jpg"
                  :style="'padding: 1.25rem; objectPosition: 0 -30px;'" />
                <div class="card-img-overlay d-flex flex-column justify-content-end">
                  <h5 class="card-title m-t-20">Sendgrid</h5>
                  <small class="text-muted">HTTP</small>
                </div>
              </div>
              <div @click="setProvider('kaleyra', 'HTTP')" class="card card-elevate text-center">
                <img class="img-fluid align-middle provider-img-size" src="assets/img/providers/kaleyra.jpg"
                  :style="'objectPosition: 0 -2px; marginLeft: auto; marginRight: auto;'" />
                <div class="card-img-overlay d-flex flex-column justify-content-end">
                  <h5 class="card-title m-t-20">Kaleyra</h5>
                  <small class="text-muted">HTTP</small>
                </div>
              </div>
              <div @click="setProvider('google', 'HTTP')" class="card card-elevate text-center">
                <img class="img-fluid align-middle provider-img-size" src="assets/img/providers/google.png"
                  :style="'objectPosition: 0 -2px; marginLeft: auto; marginRight: auto;'" />
                <div class="card-img-overlay d-flex flex-column justify-content-end">
                  <h5 class="card-title m-t-20">Google</h5>
                  <small class="text-muted">HTTP</small>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-6">
                <CardComponent variant="tertiary">
                  <template #header>
                    {{ $tc('app.general') }}
                  </template>
                  <InputComponent v-model="form.name" :label="$tc('app.name', 1)" required type="text"
                    :placeholder="$tc('app.name', 1)" />
                </CardComponent>
              </div>
              <div class="col-md-6">
                <CardComponent variant="tertiary">
                  <template #header>
                    {{ $tc('app.cost') }}
                  </template>
                  <div class="row">
                    <div class="col">
                      <InputComponent v-model="form.values.sms.vivo" label="Vivo" required type="text" v-money="money"
                        placeholder="Vivo" />
                    </div>
                    <div class="col">
                      <InputComponent v-model="form.values.sms.tim" label="Tim" required type="text" v-money="money"
                        placeholder="Tim" />
                    </div>
                    <div class="col">
                      <InputComponent v-model="form.values.sms.claro" label="Claro" required type="text" v-money="money"
                        placeholder="Claro" />
                    </div>
                    <div class="col">
                      <InputComponent v-model="form.values.sms.other" :label="$tc('app.other')" required type="text"
                        v-money="money" :placeholder="$tc('app.other')" />
                    </div>
                  </div>
                </CardComponent>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ButtonComponent variant="secondary" class="float-right ml-2" @click="$refs['store-provider'].submit()">
                  <span class="material-symbols-outlined">
                    save
                  </span>
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </FormComponent>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import ProviderService from '@/services/admin/provider.service';
import FormComponent from '@/components/form/FormComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import { mask } from 'vue-the-mask';

export default {
  name: 'CreateProvider',
  components: {
    PageHeader,
    FormComponent,
    CardComponent,
    InputComponent,
    ButtonComponent,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      customer: {},
      form: {
        auth: {},
        pool_size: 1,
        throughput: 1,
        window_size: 1,
        type: 'smpp',
        protocol: '',
        sms: true,
        email: false,
        whatsapp: false,
        rcs: false,
        values: {
          sms: {
            claro: 0,
            other: 0,
            tim: 0,
            vivo: 0,
          },
          email: {
            message: 0,
            ip: 0,
          },
          whatsapp: {
            message: 0,
          },
          rcs: {
            simple: {
              claro: 0,
              nextel: 0,
              oi: 0,
              other: 0,
              tim: 0,
              vivo: 0,
            },
            session: {
              claro: 0,
              nextel: 0,
              oi: 0,
              other: 0,
              tim: 0,
              vivo: 0,
            },
          },
        },
      },
      creating: false,
      pages: 1,
    };
  },
  methods: {
    setProvider(provider = '', protocol = '') {
      this.form.auth = {};
      this.form.type = provider;
      this.form.protocol = protocol;
      if (protocol === 'HTTP') {
        this.form.sms = true;
        this.form.email = true;
        this.form.whatsapp = true;
        this.form.rcs = true;
      } else {
        this.form.sms = true;
        this.form.email = false;
        this.form.whatsapp = false;
        this.form.rcs = false;
      }
      this.form.name = provider;
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    create() {
      this.creating = true;
      ProviderService.createProvider(this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm-component.created-supp'),
              type: 'success',
            });
            this.$router.push('./');
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}

@media (max-width: 767.98px) {
  .provider-img-size {
    padding: 4.25rem;
    padding-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .provider-img-size {
    width: 180px;
    padding: 1.25rem;
  }
}
</style>
